import {createReducer, on} from '@ngrx/store';
import {Config} from "../../types";
import {setConfig, setLoadingError} from "./actions";

export interface ConfigState {
  config: Config | null;
  hasLoadingError: boolean;
}

export const initialState: ConfigState = {
  config: null,
  hasLoadingError: false,
};

export const configReducer = createReducer(
  initialState,
  on(setConfig, (state, { config }) => ({ ...state, config })),
  on(setLoadingError, (state) => ({ ...state, hasLoadingError: true })),
);

