import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PlayerService {

  private currentlyPlaingMediaFile$$ = new BehaviorSubject<number | null>(null);

  constructor() { }

  startPlaying(mediaFileId: number) {
    this.currentlyPlaingMediaFile$$.next(mediaFileId);
  }

  stopPlaying(mediaFileId: number) {
    if (this.currentlyPlaingMediaFile$$.getValue() === mediaFileId) {
      this.currentlyPlaingMediaFile$$.next(null);
    }
  }

  get currentlyPlaying(): Observable<number | null> {
    return this.currentlyPlaingMediaFile$$.asObservable();
  }
}
