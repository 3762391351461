<article class="paper meal">
  <h2 class="secondary">{{meal.name}}</h2>
  <ul class="recipe">
    <ng-container *ngFor="let mealCourse of meal.meals_courses">
      <li *ngIf="mealCourse.course">
        <span class="recipe-product">
          <ng-container *ngIf="isComplex(mealCourse.course); else elseBlock">
            <a class="recipe-product-name complex"
               [routerLink]="[{ outlets: { popup : ['recipe', mealCourse.course.id] }}]"><span>{{mealCourse.course.name}}</span></a>
          </ng-container>
          <ng-template #elseBlock>
            <span class="recipe-product-name">{{mealCourse.course.name}}</span>
          </ng-template>
          <span class="recipe-product-quantity" *ngIf="mealCourse.quantity" [innerHTML]="mealCourse.quantity | safeHtml"></span>
        </span>

        <app-recipe *ngIf="!isComplex(mealCourse.course) && mealCourse.course.recipe && mealCourse.course.recipe.length"
                    [recipe]="mealCourse.course.recipe"></app-recipe>
      </li>
    </ng-container>

  </ul>
</article>
