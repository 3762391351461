<section class="section section-signup" [class.section-signup-thanks]="success">
  <div class="container">
    <header class="section-header">
      <h2 class="text-logo"><span class="text-logo-subtitle">Онлайн-курс</span> Тянусь к&nbsp;совершенству</h2>
    </header>
    <main>
      <article class="paper paper-padded stack" *ngIf="!success; else elseBlock">
        <header><h1>Введите новый пароль</h1></header>
        <main class="form">
          <label for="form-new-password-password">Пароль</label>
          <div class="input-container" [class.error]="getError('password')">
            <div class="input-password-container">
              <input type="password"
                     class="input input-password"
                     id="form-new-password-password"
                     [(ngModel)]="password"
                     #passwordInput>
              <span class="password-visibility-toggle material-icons" (click)="togglePasswordVisibility(passwordInput)"></span>
            </div>

            <div class="input-hint error" *ngIf="getError('password')">
              {{getError('password')}}
            </div>
          </div>

          <label for="form-new-password-phone">Телефон</label>
          <div class="input-container" [class.error]="getError('phone')">
            <input type="text"
                   class="input"
                   id="form-new-password-phone"
                   [(ngModel)]="phone"
                   [imask]="{ mask: '+00000[0000000000]' }">

            <div class="input-hint error" *ngIf="getError('phone')">
              {{getError('phone')}}
            </div>
          </div>
        </main>
        <footer class="actions">
          <a class="button button-secondary button-medium" routerLink="/">На главную</a>
          <button class="button button-primary button-medium" [disabled]="loading" (click)="submit()">Подтвердить</button>

          <p *ngIf="genericError === ERROR_PHONE_TOKEN" class="error">
            Неверный токен авторизации либо номер телефона. Убедитесь, что вы пользуетесь актуальной ссылкой и ввели номер телефона правильно.
          </p>
          <p *ngIf="genericError === ERROR_TOO_MANY_ATTEMPTS" class="error">
            Превышен лимит попыток входа. Пожалуйста, запросите <a routerLink="/forgot-password">восстановление пароля</a> еще раз.
          </p>
          <p *ngIf="genericError === ERROR_UNKNOWN" class="error">
            Произошла неизвестная ошибка. Пожалуйста, попробуйте ещё раз.
          </p>
        </footer>
      </article>

      <ng-template #elseBlock>
        <article class="paper paper-padded stack">
          <header>
            <h1>Пароль обновлён!</h1>
          </header>

          <main class="stack stack-xs">
            <p>Ваш пароль успешно обновлён.</p>
          </main>

          <footer class="actions">
            <a class="button button-primary button-medium" routerLink="/">К тренировкам</a>
          </footer>
        </article>
      </ng-template>
    </main>
  </div>
</section>
