import {Component, OnDestroy, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from "../reducer";
import {selectSubscription} from "../user/selectors";
import {PlanService} from "../plan.service";
import {Plan, Subscription} from "../../types";
import moment from 'moment';
import {combineLatest, Subscription as ReactiveSubscription} from 'rxjs';

@Component({
  selector: 'app-payment-form',
  templateUrl: './payment-form.component.html',
  styleUrls: ['./payment-form.component.scss']
})
export class PaymentFormComponent implements OnInit, OnDestroy {
  private subscriptions: ReactiveSubscription[] = [];

  userSubscription$ = this.store.select(selectSubscription);
  userSubscription: Subscription | null = null;
  plans$ = this.planService.getAll();
  plans: Plan[] | null = null;
  selectedPlan: Plan | null = null;
  selectedPlanExpiry: moment.Moment | null = null;

  constructor(
    private readonly store: Store<AppState>,
    private readonly planService: PlanService,
  ) {
  }

  ngOnInit(): void {
    this.subscriptions.push(
      combineLatest({
        userSubscription: this.userSubscription$,
        plans: this.plans$,
      })
      .subscribe(({userSubscription, plans}) => {
        this.plans = plans;
        this.userSubscription = userSubscription;
        this.selectedPlan = plans.find(({id}) => id === userSubscription?.plan?.id) || this.plans[0];
        this.updatePlanExpiry();
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  updatePlanExpiry() {
    if (!this.selectedPlan) {
      this.selectedPlanExpiry = null;
    } else {
      let startDate: moment.Moment;

      if (this.userSubscription?.plan?.id && this.userSubscription.valid && this.selectedPlan.id === this.userSubscription.plan.id) {
        startDate = moment(this.userSubscription.expires_at);
      } else {
        startDate = moment();
      }

      this.selectedPlanExpiry = startDate.add(this.selectedPlan.valid_days, 'day');
    }
  }

  selectPlan(planId: string) {
    if (!this.plans) {
      return;
    }

    this.selectedPlan = this.plans.find(({id}) => id === Number(planId)) || null;
    this.updatePlanExpiry();
  }
}
