<section class="section section-inner-page section-center">
  <div class="container">
    <header class="section-header">
      <h2 class="text-logo"><span class="text-logo-subtitle">Онлайн-курс</span> Тянусь к&nbsp;совершенству</h2>
    </header>
    <main>
      <article class="paper paper-padded stack">
        <header class="stack stack-xs">
          <h2 class="header-accent">404</h2>
          <h1>Страница не&nbsp;найдена</h1>
        </header>
        <main>
          <p class="text-important">К&nbsp;сожалению, такой страницы не&nbsp;существует.</p>
        </main>
        <footer class="actions">
          <a routerLink="/" class="button button-primary button-medium">На главную</a>
        </footer>
      </article>
    </main>
  </div>
</section>
