import {Component, OnDestroy, OnInit} from '@angular/core';
import {loadConfig} from "../config/actions";
import {NavigationEnd, Router} from "@angular/router";
import {resetPageNotFound} from "../common/actions";
import {selectConfig} from "../config/selectors";
import {selectIsPageNotFound} from "../common/selectors";
import {AppState} from "../reducer";
import {Store} from '@ngrx/store';
import {Subscription as ReactiveSubscription, filter} from 'rxjs';

@Component({
  selector: 'app-logged-out-page',
  templateUrl: './logged-out-page.component.html',
  styleUrls: ['./logged-out-page.component.scss']
})
export class LoggedOutPageComponent implements OnInit, OnDestroy {
  private subscriptions: ReactiveSubscription[] = [];
  config$ = this.store.select(selectConfig);
  isPageNotFound$ = this.store.select(selectIsPageNotFound);

  constructor(
    private readonly store: Store<AppState>,
    private readonly router: Router,
  ) {
  }

  ngOnInit() {
    this.store.dispatch(loadConfig());

    this.subscriptions.push(
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd)
      ).subscribe(() => {
        this.store.dispatch(resetPageNotFound());
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

}
