import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {CategoryService} from "../category.service";
import {PostCategory} from "../../types";

@Component({
  selector: 'app-recommendations-page',
  templateUrl: './recommendations-page.component.html',
  styleUrls: ['./recommendations-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecommendationsPageComponent implements OnInit {

  public categories: PostCategory[] | null = null;

  constructor(
    private categoryService: CategoryService,
    private changeDetectorRef: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    this.categoryService.getRecommendationsWithPosts()
      .subscribe(categories => {
        this.categories = categories.filter(({posts}) => posts && posts.length);
        this.changeDetectorRef.detectChanges();
      });
  }
}
