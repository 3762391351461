import { Injectable } from '@angular/core';
import {environment} from "../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable, tap} from "rxjs";
import {AuthResponse, User} from "../types";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  static readonly ROLE_ADMIN = 'admin';
  static readonly ROLE_EDITOR = 'editor';
  static readonly ROLE_CUSTOMER = 'customer';

  private static TTL_THRESHOLD_SECONDS = 30;
  private API_URL = environment.apiUrl;
  private accessToken: string | null = null;

  constructor(private http: HttpClient) {}

  get(): Observable<User> {
    return this.http.get<User>(`${this.API_URL}/auth/me`);
  }

  logout(): Observable<void> {
    return this.http.post<void>(`${this.API_URL}/auth/logout`, null);
  }

  getAccessToken(): string | null {
    return this.accessToken;
  }

  setAccessToken(token: string): void {
    this.accessToken = token;
  }

  setRefreshTimer(ttlSeconds: number): void {
    setTimeout(() => {
      this.refresh().subscribe();
    }, (ttlSeconds - AuthService.TTL_THRESHOLD_SECONDS) * 1000)
  }

  refresh(): Observable<AuthResponse> {
    return this.http.post<AuthResponse>(`${this.API_URL}/auth/refresh`, null, { withCredentials: true })
      .pipe(
        tap((data: AuthResponse) => {
          this.setAccessToken(data.access_token);
          this.setRefreshTimer(data.expires_in);
        })
      );
  }

  recover(email: string, captchaToken: string): Observable<{ message: string }> {
    return this.http.post<{ message: string }>(`${this.API_URL}/auth/recover`, {
      email,
      ['g-recaptcha-response']: captchaToken,
    });
  }

  updatePassword(token: string, phone: string, password: string): Observable<AuthResponse> {
    return this.http.post<AuthResponse>(`${this.API_URL}/auth/reset-password`, {
      token,
      phone,
      password,
    }, { withCredentials: true });
  }
}
