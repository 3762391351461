<div class="cell label">
  {{label}}
</div>

<div class="cell editor">
  <div class="input-container" [class.error]="error && submitted">
    <div class="input-password-container" *ngIf="type === 'password'; else elseBlock">
      <input #input class="input input-password" type="password" value="{{value}}" [attr.maxlength]="maxLength"
             (keyup)="updateValue(input.value)">
      <span class="password-visibility-toggle material-icons" (click)="togglePasswordVisibility(input)"></span>
    </div>
    <ng-template #elseBlock>
      <input #input class="input"
             value="{{value}}"
             [imask]="type === 'phone' ? { mask: '+00000[0000000000]' } : undefined"
             [attr.maxlength]="maxLength" (keyup)="updateValue(input.value)">
    </ng-template>
    <div class="input-hint error" *ngIf="error && submitted">{{error}}</div>
  </div>
</div>
