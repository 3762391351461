<ng-container *ngIf="(config$ | async) && (user$ | async); else elseBlock">
  <header id="page-header" (onLogout)="logout()"></header>

  <app-payment-stripe *ngIf="!hasParentRouteSegment('payment') && !(isPageNotFound$ | async)"></app-payment-stripe>

  <main id="page-main">
    <router-outlet></router-outlet>
  </main>

  <footer id="page-footer"></footer>
</ng-container>
<ng-template #elseBlock>
  <app-loader></app-loader>
</ng-template>
