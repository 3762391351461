import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subscription as ReactiveSubscription, tap, switchMap} from "rxjs";
import {Plan, User, WayforpayOrderOptions} from "../../types";
import {Store} from '@ngrx/store';
import {selectUser} from "../user/selectors";
import {AppState} from "../reducer";
import {PaymentService} from "../payment.service";
import {Router} from "@angular/router";
import {setUser} from "../user/actions";
import {AuthService} from "../auth.service";

@Component({
  selector: 'app-wayforpay-button',
  templateUrl: './wayforpay-button.component.html',
  styleUrls: ['./wayforpay-button.component.scss']
})
export class WayforpayButtonComponent implements OnInit, OnDestroy {

  private subscriptions: ReactiveSubscription[] = [];
  private orderOptions: WayforpayOrderOptions | null = null;
  private messageHandler: ((event: any) => void) | null = null;
  private plan: Plan | null = null;
  public error: string = '';
  public paying: boolean = false;

  @Input('plan')
  public set setPlan(plan: Plan | null) {
    this.orderOptions = null;
    this.plan = plan;

    if (plan) {
      this.paymentService.createWayforpayOrder(plan).subscribe((orderOptions: WayforpayOrderOptions) => {
        this.orderOptions = orderOptions;
      });
    }
  };

  public hasOrderOptions(): boolean {
    return !!this.orderOptions;
  }

  public user: User | null = null;

  private wayforpay = new (window as any).Wayforpay();

  constructor(
    private readonly store: Store<AppState>,
    private readonly authService: AuthService,
    private readonly paymentService: PaymentService,
    private readonly router: Router,
  ) { }

  ngOnInit(): void {
    this.subscriptions.push(this.store.select(selectUser).subscribe((user: User | null) => {
      this.user = user;
    }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());

    if (this.messageHandler) {
      window.removeEventListener('message', this.messageHandler);
    }
  }

  pay(): void {
    if (!this.messageHandler) {
      this.messageHandler = (event) => {
        if(
          event.data === "WfpWidgetEventClose" ||      //при закрытии виджета пользователем
          event.data === "WfpWidgetEventApproved" ||   //при успешном завершении операции
          event.data === "WfpWidgetEventDeclined" ||   //при неуспешном завершении
          event.data === "WfpWidgetEventPending")      // транзакция на обработке
        {
          if (event.data === "WfpWidgetEventApproved" && this.user && this.orderOptions) {
            this.paymentService.createSubscription(this.user, this.orderOptions.orderReference)
              .pipe(
                switchMap(() => this.authService.get()),
                tap(
                  user => {
                    this.store.dispatch(setUser(user));
                    this.router.navigate(['/payment/thanks'])
                  },
                  (err: any) => {
                    this.error = err.message;
                  }
                )
              )
              .subscribe();

          }

          if (["WfpWidgetEventPending"].includes(event.data)) {
            this.router.navigate(['/payment/pending'])
          }

          if (["WfpWidgetEventDeclined", "WfpWidgetEventClose"].includes(event.data)) {
            // Reload payment options
            this.setPlan = this.plan;
          }

          this.paying = false;
        }
      };

      window.addEventListener("message", this.messageHandler);
    }

    this.error = '';
    this.wayforpay.run({ ...this.orderOptions, straightWidget: true });
  }
}
