import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  Inject,
  ViewChild,
  ElementRef,
  AfterViewInit,
  OnDestroy,
} from '@angular/core';
import {DOCUMENT} from "@angular/common";

@Component({
  selector: 'app-profile-popup',
  templateUrl: './profile-popup.component.html',
  styleUrls: ['./profile-popup.component.scss']
})
export class ProfilePopupComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('popup') popup: ElementRef<HTMLElement> | undefined;
  @Input() toggle: HTMLElement | undefined;
  @Input('isVisible') set setIsVisible(isVisible: boolean) {
    this.isVisible = isVisible;
  };
  @Output() onClose = new EventEmitter<void>();

  public hideOnClickOutsideBound: (event: MouseEvent) => void;
  public isVisible: boolean = false;

  constructor(
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.hideOnClickOutsideBound = this.hideOnClickOutside.bind(this);
  }

  ngOnInit(): void {

  }

  hideOnClickOutside(event: MouseEvent) {
    let element: HTMLElement | null = event.target as HTMLElement;
    while (element && element !== this.popup?.nativeElement && element !== this.toggle) {
      element = element.parentElement;
    }

    if (!element) {
      this.isVisible = false;
      this.onClose.emit();
    }
  }

  ngAfterViewInit(): void {
    this.document.addEventListener('click', this.hideOnClickOutsideBound);
  }

  ngOnDestroy(): void {
    this.document.removeEventListener('click', this.hideOnClickOutsideBound);
  }

  @Output() onLogout = new EventEmitter<void>();

  logout() {
    this.onLogout.emit();
  }
}
