<article>
  <ng-container *ngIf="training.media_file && training.media_file.type === 'video'">
    <app-training-video [startFullscreen]="!showExtendedInfo"
                        [video]="training.media_file"
                        class="video"></app-training-video>
  </ng-container>

  <header>
    <a routerLink="/training/{{training.id}}" *ngIf="!showExtendedInfo; else name" class="name-link">
      <ng-container *ngTemplateOutlet="name"></ng-container>
    </a>

    <ng-template #name>
      <h3 class="secondary training-name">{{training.name}}</h3>
    </ng-template>
  </header>

  <app-training-tags class="tags" [showExtendedInfo]="showExtendedInfo" [training]="training"></app-training-tags>

  <aside>
    <button class="button button-xsmall button-secondary" (click)="markCompleted()">Выполнить</button>
    <span class="completed" *ngIf="views" [ngPlural]="views">
      <span class="material-icons completed-icon"></span>
        <ng-template ngPluralCase="=1">Выполнено {{views}} раз</ng-template>
        <ng-template ngPluralCase="few">Выполнено {{views}} раза</ng-template>
        <ng-template ngPluralCase="other">Выполнено {{views}} раз</ng-template>
    </span>
  </aside>

  <main *ngIf="showExtendedInfo" [innerHTML]="training.html_contents | safeHtml" class="stack stack-xs"></main>
</article>
