<section class="section section-trainings">
  <div class="container">
    <header class="section-header">
      <h2>{{category.name}}</h2>
      <div [innerHTML]="category.description" class="stack stack-xs"></div>
    </header>
    <main>
      <section class="trainings columns columns-row-gap-lg columns-md-2 columns-xl-3">
        <app-training *ngFor="let post of category.posts" [training]="post" (onComplete)="markCompleted(post)"></app-training>
      </section>
    </main>
  </div>
</section>
