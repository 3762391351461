import {Component, Input, OnInit} from '@angular/core';
import {Post} from "../../../types";

@Component({
  selector: 'app-training-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.scss']
})
export class TagsComponent implements OnInit {

  tags: { [key: string]: string[] } = {
    zone: []
  };

  @Input('training')
  set setTraining(training: Post) {
    this.tags = {
      zone: []
    };

    if (!training.tags) {
      return;
    }

    training.tags.forEach(tag => {
      const type = tag.type || '';
      if (!this.tags[type]) {
        this.tags[type] = [];
      }

      this.tags[type].push(tag.name);
    });
  }

  training!: Post;

  @Input()
  showExtendedInfo: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

}
