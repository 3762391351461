<div class="container" *ngIf="loggedOut; else elseBlock">
  <a class="logo" routerLink="/">
    <img src="assets/images/logo.svg" width="196" height="62" alt="{{siteName$ | async}}">
  </a>
</div>

<ng-template #elseBlock>
  <div class="container">
    <span class="material-icons menu-toggle" (click)="toggleMenu()" *ngIf="!loggedOut">menu</span>
    <a class="logo" routerLink="/">
      <img src="assets/images/logo.svg" width="196" height="62" alt="{{siteName$ | async}}">
    </a>
    <nav class="nav-links">
      <span class="backdrop" (click)="closeMenu()"></span>
      <ng-container *ngIf="(canViewInnerPages$ | async)">
        <a routerLink="/trainings" [class.active]="isInTrainings()" (click)="closeMenu()">Тренировки</a>
        <a routerLink="/recommendations" routerLinkActive="active" (click)="closeMenu()">Рекомендации</a>
        <a routerLink="/diet" routerLinkActive="active" (click)="closeMenu()">Рацион</a>
      </ng-container>
      <a class="mobile" routerLink="/settings" routerLinkActive="active" (click)="closeMenu()">Настройки</a>
    </nav>

    <nav class="nav-links">
      <span class="link popup-toggle" (click)="togglePopup()" #toggle>
          <span class="avatar">{{initials$ | async}}</span><span class="material-icons arrow">keyboard_arrow_down</span>
      </span>
      <span class="user-name">{{firstName$ | async}}</span>
      <button class="button button-secondary button-contrast button-xsmall logout" (click)="logout()">Выход</button>
    </nav>
  </div>

  <app-profile-popup (onLogout)="logout()" [isVisible]="isPopupVisible" [toggle]="toggle" (onClose)="closePopup()"></app-profile-popup>
</ng-template>
