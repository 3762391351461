<ng-container *ngIf="!editing">
  <div class="cell label">
    {{label}}
  </div>

  <div class="cell value">
    {{resultingValue}}
  </div>
</ng-container>

<ng-container *ngIf="editing">
  <app-settings-page-editor *ngFor="let input of inputs"
                            class="editor-row"
                            [label]="input.label"
                            [error]="errors[input.name]"
                            [value]="values[input.name]"
                            [type]="input.type"
                            [maxLength]="input.maxLength"
                            [submitted]="submitted[input.name]"
                            (onChange)="updateValue(input.name, $event)"></app-settings-page-editor>
</ng-container>


<div class="cell actions">
  <span class="link" (click)="startEditing()" *ngIf="!editing && canEdit">Изменить</span>
  <ng-container *ngIf="editing">
    <button class="button button-secondary button-small" (click)="cancelEditing()">Отмена</button>
    <button class="button button-primary button-small" (click)="saveEditing()">Сохранить</button>
  </ng-container>
</div>

