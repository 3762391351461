import {Component, OnInit, ViewChild} from '@angular/core';
import {RecaptchaComponent} from 'ng-recaptcha';
import {AuthService} from "../auth.service";

@Component({
  selector: 'app-recover-password-page',
  templateUrl: './recover-password-page.component.html',
  styleUrls: ['./recover-password-page.component.scss']
})
export class RecoverPasswordPageComponent implements OnInit {
  @ViewChild(RecaptchaComponent)
  public captchaRef: RecaptchaComponent | null = null;

  public email: string = '';
  public error: string = '';
  public success: boolean = false;

  public loading: boolean = false;

  public ERROR_UNSENT = 'Не удалось отправить письмо со ссылкой. Пожалуйста, попробуйте позже';
  public ERROR_UNKNOWN = 'Произошла неизвестная ошибка. Пожалуйста, попробуйте ещё раз';
  public ERROR_EMAIL_REQUIRED = 'Пожалуйста, введите адрес email';

  constructor(
    private authService: AuthService,
  ) { }

  ngOnInit(): void {
  }

  executeCaptcha() {
    this.error = '';
    if (!this.email) {
      this.error = this.ERROR_EMAIL_REQUIRED;
      return;
    }

    this.captchaRef?.execute();
  }

  submit(captcha: string): void {
    this.error = '';
    this.loading = true;
    if (captcha && this.email) {
      this.authService.recover(this.email, captcha)
        .subscribe(
          () => {
            this.success = true;
            this.loading = false;
            this.captchaRef?.reset();
          },
          (error) => {
            this.loading = false;
            switch (error.status) {
              case 503:
                this.error = this.ERROR_UNSENT;
                break;
              default:
                this.error = this.ERROR_UNKNOWN;
            }
          },
        );
    }
  }

}
