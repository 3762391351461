import { Injectable } from '@angular/core';
import {
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable, tap } from 'rxjs';
import {AuthService} from "./auth.service";
import {User} from "../types";
import {setUser} from "./user/actions";
import {AppState} from "./reducer";
import {Store} from '@ngrx/store';

@Injectable({
  providedIn: 'root'
})
export class UserResolver implements Resolve<User> {
  constructor(
    private readonly authService: AuthService,
    private readonly store: Store<AppState>,
  ) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<User> {
    return this.authService.get().pipe(
      tap(user => {
        this.store.dispatch(setUser(user));
      })
    );
  }
}
