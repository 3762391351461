import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {AppState} from "../reducer";
import {Store} from '@ngrx/store';
import {selectCourses} from "../meals/selectors";
import {openPopup, closePopup} from "../common/actions";
import {ActivatedRoute, Router} from "@angular/router";
import {Course} from "../../types";

@Component({
  selector: 'app-recipe-popup',
  templateUrl: './recipe-popup.component.html',
  styleUrls: ['./recipe-popup.component.scss']
})
export class RecipePopupComponent implements OnInit, OnDestroy {

  course: Course | null = null;
  courseId: number | null = null;

  @HostListener('click', ['$event'])
  private clickOutside($event: any) {
    if ($event.target.tagName.toLowerCase() === 'app-recipe-popup') {
      this.router.navigate(['/diet']);
    }
  }

  constructor(
    private readonly store: Store<AppState>,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.store.dispatch(openPopup());
    this.courseId = +this.activatedRoute.snapshot.params['id'];

    this.store.select(selectCourses).subscribe(courses => {
      this.course = courses && courses.find(({ id }) => id === this.courseId) || null;

      if (!this.course) {
        this.router.navigate(['/diet']);
      }
    });
  }

  ngOnDestroy() {
    this.store.dispatch(closePopup());
  }
}
