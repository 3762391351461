import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {OwlOptions} from "ngx-owl-carousel-o";
import {MealService} from "../meal.service";
import {PostCategory} from "../../types";
import {PageService} from "../page.service";
import {AppState} from "../reducer";
import {Store} from '@ngrx/store';
import {setCategories, setCourses} from "../meals/actions";
import {selectCategoriesWithMealsAndCourses} from "../meals/selectors";

@Component({
  selector: 'app-diet-page',
  templateUrl: './diet-page.component.html',
  styleUrls: ['./diet-page.component.scss']
})
export class DietPageComponent implements OnInit {

  carouselOptions: OwlOptions = {
    loop: true,
    dots: false,
    responsive: {
      0: {
        items: 1
      },
      640: {
        items: 2
      },
      1100: {
        items: 3
      }
    },
    nav: true,
    navText: ['', ''],
    margin: 30,
  }

  public categories: PostCategory[] | null = null;
  public pageHeader: string | null = null;
  public pageDescription: string | null = null;

  constructor(
    private readonly mealService: MealService,
    private readonly pageService: PageService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly store: Store<AppState>,
  ) { }

  ngOnInit(): void {
    this.mealService.getCategoriesWithMeals()
      .subscribe(categories => {
        this.store.dispatch(setCategories({ categories }));
      });

    this.mealService.getCourses()
      .subscribe(courses => {
        this.store.dispatch(setCourses({ courses }));
      })

    this.store.select(selectCategoriesWithMealsAndCourses)
      .subscribe(categoriesWithMealsAndCourses => {
        if (categoriesWithMealsAndCourses) {
          this.categories = categoriesWithMealsAndCourses.filter(({ posts }) => posts && posts.length);
        } else {
          this.categories = null;
        }

        this.changeDetectorRef.detectChanges();
      })

    this.pageService.getOneBySlug('diet')
      .subscribe(page => {
        this.pageHeader = page.name;
        this.pageDescription = page.html_contents;
      });
  }
}
