import { createAction } from '@ngrx/store';
import {
  COMMON_SET_PAGE_NOT_FOUND,
  COMMON_RESET_PAGE_NOT_FOUND,
  COMMON_OPEN_POPUP,
  COMMON_CLOSE_POPUP
} from "./action-types";

export const setPageNotFound = createAction(COMMON_SET_PAGE_NOT_FOUND);
export const resetPageNotFound = createAction(COMMON_RESET_PAGE_NOT_FOUND);
export const openPopup = createAction(COMMON_OPEN_POPUP);
export const closePopup = createAction(COMMON_CLOSE_POPUP);
