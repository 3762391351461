import {createReducer, on} from "@ngrx/store";
import {setCategories} from "./actions";
import {PostCategory} from "../../types";

export interface TrainingsState {
  categories: PostCategory[] | null;
}

export const initialState: TrainingsState = {
  categories: null,
};

export const trainingsReducer = createReducer(
  initialState,
  on(setCategories, (state, props: { categories: PostCategory[] }) => ({...state, categories: props.categories})),
);

