<section class="section section-signup" [class.section-signup-thanks]="success">
  <div class="container">
    <header class="section-header">
      <h2 class="text-logo"><span class="text-logo-subtitle">Онлайн-курс</span> Тянусь к&nbsp;совершенству</h2>
    </header>
    <main>
      <article class="paper paper-padded stack" *ngIf="!success; else elseBlock">
        <header><h1>Восстановление пароля</h1></header>
        <main class="form">
          <label for="form-recover-password-email">Email</label>
          <div class="input-container" [class.error]="error">
            <input type="text"
                   class="input"
                   id="form-recover-password-email"
                   [(ngModel)]="email">
            <div class="input-hint error" *ngIf="error">
              {{error}}
            </div>
          </div>

          <re-captcha (resolved)="$event && submit($event)"
                      size="invisible"
                      [style]="{position: 'absolute'}"
                      #captchaRef="reCaptcha"></re-captcha>
        </main>
        <footer class="actions">
          <a class="button button-secondary button-medium" routerLink="/">Назад</a>
          <button class="button button-primary button-medium" [disabled]="loading" (click)="executeCaptcha()">Сбросить пароль</button>
        </footer>
      </article>

      <ng-template #elseBlock>
        <article class="paper paper-padded stack">
          <header>
            <h1>Проверьте почту</h1>
          </header>

          <main class="stack stack-xs">
            <p>Если ваш электорнный адрес {{email}} зарегистрирован в системе, на него придёт письмо со ссылкой для сброса пароля.</p>
            <p>Если письма нет в течение получаса, посмотрите в папке «спам». Если его нет и там, вероятнее всего, вы еще не зарегистрированы.</p>
          </main>

          <footer class="actions">
            <a class="button button-primary button-medium" routerLink="/">На главную</a>
          </footer>
        </article>
      </ng-template>
    </main>
  </div>
</section>
