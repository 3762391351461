import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-settings-page-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditorComponent implements OnInit {

  @Input() label!: string;
  @Input() value: string | null = null;
  @Input() type: string | undefined;
  @Input() maxLength: number | undefined;

  @Input() error: string | null = null;
  @Input() submitted: boolean = true;

  @Output() onChange = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {
  }

  updateValue(value: string) {
    this.onChange.emit(value);
  }

  togglePasswordVisibility(input: HTMLInputElement) {
    input.type = input.type === 'password' ? 'text' : 'password';
  }
}
