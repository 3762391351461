import {Component, Input, OnInit} from '@angular/core';
import {Post, PostCategory, PostView} from "../../types";
import {PostService} from "../post.service";
import {tap} from 'rxjs';

@Component({
  selector: 'app-trainings-list',
  templateUrl: './trainings-list.component.html',
  styleUrls: ['./trainings-list.component.scss']
})
export class TrainingsListComponent implements OnInit {

  @Input()
  public category!: PostCategory;

  constructor(
    private postService: PostService,
  ) { }

  ngOnInit(): void {
  }

  markCompleted(postToUpdate: Post): void {
    this.postService.markPostCompleted(postToUpdate)
      .pipe(
        tap((view: PostView) => {
          const category = this.category;
          if (category?.posts) {
            const postIndex = category.posts.findIndex((post) => post.id === postToUpdate.id);

            category.posts = [
              ...category.posts.slice(0, postIndex),
              {
                ...postToUpdate,
                views: [...(postToUpdate.views || []), view],
              },
              ...category.posts.slice(postIndex + 1)
            ]

            if (category.posts.every(post => post.views?.length)) {
              category.viewed = true;
            }
          }
        })
      )
      .subscribe();
  }
}
