<div class="container">
  <article class="paper paper-padded recipe-popup" *ngIf="course">
    <header><h1>{{course.name}}</h1></header>
    <aside *ngIf="course.recipe && course.recipe.length" class="stack">
      <h2 class="secondary">Ингредиенты</h2>
      <app-recipe [recipe]="course.recipe"></app-recipe>
    </aside>

    <main *ngIf="course.description" class="stack">
      <h2 class="secondary">Приготовление</h2>
      <div [innerHTML]="course.description | safeHtml" class="stack stack-xs"></div>
    </main>
    <footer class="actions">
      <a class="button button-primary button-medium" routerLink="/diet">Закрыть</a>
    </footer>
  </article>
</div>
