<img class="logo" src="assets/images/logo.svg" width="196" height="62" alt="{{siteName$ | async}}">
<nav class="nav-icons">
  <a href="mailto:{{contactEmail$ | async}}"><span class="icon-button icon-button-email"></span>{{contactEmail$ | async}}</a>
  <a href="tel:{{contactPhone$ | async}}"><span class="icon-button icon-button-phone"></span>{{(contactPhone$ | async) | formatPhoneNumber}}</a>
  <a href="https://www.instagram.com/{{contactInstagram$ | async}}/"><span class="icon-button icon-button-instagram"></span>@{{contactInstagram$ | async}}</a>
</nav>
<p class="copyright">&copy; Тянусь к совершенству {{year}}</p>
<nav class="nav-links">
  <a href="{{landingUrl}}/terms-and-conditions">Оферта</a>
  <a href="{{landingUrl}}/privacy-policy">Политика конфиденциальности</a>
</nav>
