import {Component, OnInit} from '@angular/core';
import {tap} from 'rxjs';
import {AuthService} from "../auth.service";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthResponse, FormErrors, FormMessages, User} from "../../types";
import {setUser} from "../user/actions";
import {HttpErrorResponse} from "@angular/common/http";
import {AppState} from "../reducer";
import {Store} from '@ngrx/store';

@Component({
  selector: 'app-new-password-page',
  templateUrl: './new-password-page.component.html',
  styleUrls: ['./new-password-page.component.scss']
})
export class NewPasswordPageComponent implements OnInit {

  public readonly ERROR_PHONE_TOKEN = 'ERROR_PHONE_TOKEN';
  public readonly ERROR_TOO_MANY_ATTEMPTS = 'ERROR_TOO_MANY_ATTEMPTS';
  public readonly ERROR_UNKNOWN = 'ERROR_UNKNOWN';

  public password: string = '';
  public token: string = '';
  public phone: string = '';

  public success: boolean = false;
  public loading: boolean = false;

  public genericError: string | null = null;
  public errors: FormErrors = {};
  public errorMessages: FormMessages = {
    password: {
      'validation.required': 'Пожалуйста, введите новый пароль',
      'validation.min.string': 'Пароль должен быть не менее 6 символов длиной',
    },
    phone: {
      'validation.required': 'Пожалуйста, введите номер телефона',
      'validation.regex': 'Пожалуйста, введите номер в международном формате',
    },
  }

  constructor(
    private readonly router: Router,
    private readonly authService: AuthService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly store: Store<AppState>,
  ) { }

  ngOnInit(): void {
    this.token = this.activatedRoute.snapshot.params['token'];
    if (!this.token) {
      this.router.navigate(['/']);
      return;
    }
  }

  setErrors(errors: FormErrors) {
    this.errors = errors;
  }

  getError(fieldName: string): string {
    if (!this.errors[fieldName]?.length) {
      return '';
    }

    return this.errorMessages[fieldName][this.errors[fieldName][0]];
  }

  togglePasswordVisibility(input: HTMLInputElement) {
    input.type = input.type === 'password' ? 'text' : 'password';
  }

  submit(): void {
    this.genericError = null;
    this.errors = {};
    this.authService.updatePassword(this.token, this.phone, this.password)
      .pipe(
        tap(
          (response: AuthResponse) => {
            this.store.dispatch(setUser(response.user));
            this.success = true;
          },
          (response: HttpErrorResponse) => {
            switch (response.status) {
              case 404:
                this.genericError = this.ERROR_PHONE_TOKEN;
                break;
              case 422:
                this.genericError = this.ERROR_TOO_MANY_ATTEMPTS;
                break;
              case 400:
                this.setErrors(response.error);
                break;
              default:
                this.genericError = this.ERROR_UNKNOWN;
            }
          },
        )
      )
      .subscribe();
  }

}
