<article class="paper paper-padded stack">
  <header>
    <h1>Спасибо за покупку!</h1>
  </header>

  <main class="stack stack-xs" *ngIf="subscription">
    <p>Ваш пакет «{{subscription.plan?.name}}»
      теперь действителен до {{subscription.expires_at | amLocale:'ru' | amDateFormat:'D&nbsp;MMMM&nbsp;YYYY'}}.
    </p>
    <p>Приятных тренировок!</p>
  </main>

  <footer class="actions">
    <a class="button button-primary button-medium" routerLink="/">К тренировкам</a>
  </footer>
</article>
