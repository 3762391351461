import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChildren} from '@angular/core';
import {PostCategory} from "../../types";
import {AppState} from "../reducer";
import {Store} from "@ngrx/store";
import {combineLatest, Subscription as ReactiveSubscription, filter} from 'rxjs';
import {selectCategories} from "../trainings/selectors";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";

@Component({
  selector: 'app-trainings-child-category',
  templateUrl: './trainings-child-category.component.html',
  styleUrls: ['./trainings-child-category.component.scss']
})
export class TrainingsChildCategoryComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChildren('childCategory') links!: QueryList<ElementRef>

  private subscriptions: ReactiveSubscription[] = [];

  public childCategory: PostCategory | null = null;
  public parentCategory: PostCategory | null = null;

  public get selectedCategory() {
    return this.childCategory || this.parentCategory;
  }

  constructor(
    private readonly store: Store<AppState>,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
  ) {}

  ngAfterViewInit() {
    if (this.childCategory) {
      const activeLink = this.links.find(
        (e) => e.nativeElement.getAttribute('href').endsWith(this.router.url)
      );

      if (activeLink) {
        activeLink.nativeElement.scrollIntoView(false);
      }
    }
  }

  ngOnInit(): void {
    this.subscriptions.push(
      combineLatest({
        params: this.activatedRoute.params,
        categories: this.store.select(selectCategories),
      })
        .subscribe(({ params, categories }) => {
          const childSlug = params['childCategory'];
          const parentSlug = params['parentCategory'];

          if (categories) {
            const parentCategory = categories.find(category => category.slug === parentSlug);

            if (!parentSlug || !parentCategory) {
              this.router.navigate(['trainings', categories[0].slug]);
              return;
            }

            let childCategory: PostCategory | null = null;

            if (parentCategory.children && parentCategory.children.length) {
              childCategory = parentCategory.children.find(child => child.slug === childSlug) || null;

              if (!childSlug || !childCategory) {
                childCategory = parentCategory.children.find(({ viewed }) => !viewed) || parentCategory.children[0];
                this.router.navigate(['trainings', parentCategory.slug, childCategory.slug]);
                return;
              }
            }

            this.parentCategory = parentCategory;
            this.childCategory = childCategory;
          }
        }),

      this.router.events.pipe(
        filter((event): event is NavigationEnd => event instanceof NavigationEnd),
      ).subscribe((event: NavigationEnd) => {
        if (this.childCategory) {
          const activeLink = this.links.find(
            (e) => e.nativeElement.getAttribute('href').endsWith(event.url)
          );

          if (activeLink) {
            activeLink.nativeElement.scrollIntoView(false);
          }
        }
      }),
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
