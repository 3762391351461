import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'formatPhoneNumber'
})
export class FormatPhoneNumberPipe implements PipeTransform {

  transform(internationalPhoneNumber: string | undefined | null): string | null {
    if (!internationalPhoneNumber) {
      return null;
    }

    return internationalPhoneNumber.replace(/^\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)(\d{2})(\d{3})(\d*)$/, (match, p1, p2, p3, p4) => {
      const phone = `+${p1} ${p2} ${p3}`;
      if (!p4) {
        return phone;
      }
      return `${phone}-${p4.match(/\d{1,2}/g).join('-')}`;
    });
  }

}
