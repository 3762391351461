<section class="section" *ngIf="training; else elseBlock">
  <div class="container">
    <a [routerLink]="linkBack" class="back"><span class="material-icons">chevron_left</span> <span class="label">Назад</span></a>
    <app-training [training]="training" (onComplete)="markCompleted()" [showExtendedInfo]="true"></app-training>
  </div>
</section>

<ng-template #elseBlock>
  <app-loader></app-loader>
</ng-template>
