import { Component, OnInit } from '@angular/core';
import {selectConfig} from "../config/selectors";
import {selectUser} from "../user/selectors";
import {AppState} from "../reducer";
import {Store} from '@ngrx/store';

@Component({
  selector: 'app-payment-stripe',
  templateUrl: './payment-stripe.component.html',
  styleUrls: ['./payment-stripe.component.scss']
})
export class PaymentStripeComponent implements OnInit {
  config$ = this.store.select(selectConfig);
  user$ = this.store.select(selectUser);
  maxUserDiscount: number = 0;

  constructor(private readonly store: Store<AppState>) { }

  ngOnInit(): void {
    this.config$.subscribe(config => {
      if (config && config.maxUserDiscount) {
        this.maxUserDiscount = config.maxUserDiscount * 100;
      }
    })
  }

}
