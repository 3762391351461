<section class="section section-signup section-signup-payment">
  <div class="container">
    <header class="section-header">
      <h2 class="text-logo"><span class="text-logo-subtitle">Онлайн-курс</span> Тянусь к&nbsp;совершенству</h2>
    </header>
    <main>
      <router-outlet></router-outlet>
    </main>
  </div>
</section>
