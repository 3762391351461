import {Component, Inject, OnInit} from '@angular/core';
import {DOCUMENT} from "@angular/common";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent implements OnInit {

  public message: string = '';

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.message = this.activatedRoute.snapshot.queryParams['message'];
  }

  reload() {
    this.document.location.reload();
  }

}
