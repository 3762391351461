import Hls from 'hls.js';
import { PlyrDriver, PlyrDriverCreateParams, PlyrDriverDestroyParams, PlyrDriverUpdateSourceParams } from 'ngx-plyr';
import Plyr from 'plyr';

export class HlsjsPlyrDriver implements PlyrDriver {

  hls = new Hls({
    xhrSetup: function (xhr) {
      xhr.withCredentials = true; // do send cookies
    },
  });

  private loaded = false;
  private video: HTMLVideoElement | null = null;
  private nativelySupported = false;
  private librarySupported = Hls.isSupported();

  constructor(private autoload: boolean) { }

  create(params: PlyrDriverCreateParams) {
    this.video = params.videoElement;
    this.nativelySupported = Boolean(this.video.canPlayType('application/vnd.apple.mpegurl'));

    if (this.librarySupported) {
      this.hls.attachMedia(params.videoElement);
    }

    return new Plyr(params.videoElement, params.options);
  }

  updateSource(params: PlyrDriverUpdateSourceParams) {
    if (!this.librarySupported) {
      params.plyr.source = params.source;
      params.videoElement.setAttribute('crossorigin', 'use-credentials');
    } else if (this.autoload && params.source.sources.length) {
      this.load(params.source.sources[0].src);
    } else if (!this.autoload && params.source.poster) {
      params.plyr.poster = params.source.poster;
    }
  }

  destroy(params: PlyrDriverDestroyParams) {
    params.plyr.destroy();

    if (this.librarySupported) {
      this.hls.detachMedia();
      this.hls.destroy();
    }
  }

  load(src: string) {
    if (!this.loaded) {
      this.loaded = true;

      if (this.librarySupported) {
        this.hls.loadSource(src);
      }
    }
  }
}
