<article class="paper paper-padded stack">
  <header><h1>Оплата</h1></header>
  <main class="stack">
    <section class="form" *ngIf="plans && selectedPlan">
      <label for="form-signup-plan">Тариф</label>
      <div class="input-container">
        <select class="input" name="plan" id="form-signup-plan" #select (change)="selectPlan(select.value)">
          <option [value]="plan.id" [selected]="plan.id === selectedPlan.id" *ngFor="let plan of plans">{{plan.name}}</option>
        </select>
      </div>
    </section>

    <p *ngIf="selectedPlan">
      Стоимость <span class="price"><ng-container *ngIf="selectedPlan.price_uah === selectedPlan.discount_price_uah; else discountPrice">{{selectedPlan.price_uah}} грн.</ng-container>
      <ng-template #discountPrice><s>{{selectedPlan.price_uah}} грн.</s> {{selectedPlan.discount_price_uah}} грн.</ng-template></span>,
      будет действителен до&nbsp;{{selectedPlanExpiry | amLocale:'ru' | amDateFormat:'D&nbsp;MMMM&nbsp;YYYY'}}
    </p>

    <p class="error" *ngIf="userSubscription?.valid && userSubscription?.plan?.id !== selectedPlan?.id">
      Внимание! Ваш текущий план “{{userSubscription?.plan?.name}}”
      до&nbsp;{{userSubscription?.expires_at | amLocale:'ru' | amDateFormat:'D&nbsp;MMMM&nbsp;YYYY'}}
      будет аннулирован!
    </p>
  </main>
  <footer class="actions">
    <app-wayforpay-button [plan]="selectedPlan" *ngIf="selectedPlan"></app-wayforpay-button>
  </footer>
</article>
