<app-loader *ngIf="!categories || !pageHeader; else elseBlock"></app-loader>
<ng-template #elseBlock>
  <section class="section">
    <div class="container">
      <header class="section-header">
        <h1>{{pageHeader}}</h1>
        <div class="section-header-text" [innerHTML]="pageDescription | safeHtml"></div>
      </header>
    </div>
  </section>

  <section class="section section-diet section-diet-{{category.slug}}" *ngFor="let category of categories">
    <div class="container">
      <header class="section-header">
        <img alt="" src="/assets/images/icon-meal-{{category.slug}}.svg">
        <h1 class="secondary">{{category.name}}</h1>
      </header>
      <main>
        <owl-carousel-o [options]="carouselOptions">
          <ng-template carouselSlide [id]="post.id + ''" *ngFor="let post of category.posts">
            <app-diet-page-meal [meal]="post"></app-diet-page-meal>
          </ng-template>
        </owl-carousel-o>
      </main>
    </div>
  </section>
</ng-template>

<router-outlet name="popup"></router-outlet>
