import {Component, OnInit} from '@angular/core';
import {CategoryService} from "../category.service";
import {PostCategory} from "../../types";
import {AppState} from "../reducer";
import {Store} from '@ngrx/store';
import {setCategories} from "../trainings/actions";

@Component({
  selector: 'app-trainings-page',
  templateUrl: './trainings-page.component.html',
  styleUrls: ['./trainings-page.component.scss']
})
export class TrainingsPageComponent implements OnInit {

  public categories: PostCategory[] | null = null;

  constructor(
    private readonly categoryService: CategoryService,
    private readonly store: Store<AppState>,
  ) { }

  loadCategories() {
    this.categoryService.getTrainingTree().subscribe((categories) => {
      this.store.dispatch(setCategories({ categories }));
      this.categories = categories;
    });
  }

  ngOnInit(): void {
    this.loadCategories();
  }
}
