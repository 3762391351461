import {createSelector} from '@ngrx/store';
import {Config} from '../../types';
import {selectConfigState} from "../selectors";
import {ConfigState} from "./reducer";

export const selectConfig = createSelector(
  selectConfigState,
  (state: ConfigState) => state.config
);

export const selectSiteName = createSelector(selectConfig, (config: Config | null) => config ? config.siteName : '');
export const selectContactEmail = createSelector(selectConfig, (config: Config | null) => config ? config.contactEmail : '');
export const selectContactPhone = createSelector(selectConfig, (config: Config | null) => config ? config.contactPhone : '');
export const selectContactInstagram = createSelector(selectConfig, (config: Config | null) => config ? config.contactInstagram : '');
