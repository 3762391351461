<app-loader *ngIf="!categories; else elseBlock"></app-loader>
<ng-template #elseBlock>
  <section class="section">
    <div class="container">
      <header class="section-header"><h1>Рекомендации</h1></header>
      <main>
        <article *ngFor="let category of categories">
          <header><h1 class="secondary">{{category.name}}</h1></header>
          <main>
            <dl class="faq accordion">
              <app-accordion-item *ngFor="let post of category.posts"
                                  [title]="post.name | safeHtml"
                                  [contents]="post.html_contents | safeHtml"
              ></app-accordion-item>

            </dl>
          </main>
        </article>
      </main>
    </div>
  </section>
</ng-template>
