import {createSelector} from '@ngrx/store';
import {Subscription, User} from '../../types';
import {selectUserState} from "../selectors";
import {UserState} from "./reducer";
import {AuthService} from "../auth.service";

export const selectUser = createSelector(
  selectUserState,
  (state: UserState) => state.user
);

export const selectInitials = createSelector(selectUser, (user: User | null) => user ? user.initials : '');
export const selectFullName = createSelector(selectUser, (user: User | null) => user ? user.full_name : '');
export const selectFirstName = createSelector(selectUser, (user: User | null) => user ? user.first_name : '');
export const selectLastName = createSelector(selectUser, (user: User | null) => user ? user.last_name : '');
export const selectEmail = createSelector(selectUser, (user: User | null) => user ? user.email : '');
export const selectPhone = createSelector(selectUser, (user: User | null) => user ? user.phone : '');
export const selectRole = createSelector(selectUser, (user: User | null) => user ? user.role : '');
export const selectHasEditorRole = createSelector(selectRole, (role: string) => [AuthService.ROLE_ADMIN, AuthService.ROLE_EDITOR].includes(role));
export const selectSubscription = createSelector(selectUser, (user: User | null) => user && user.subscription || null);
export const selectHasAccessToContent = createSelector(
  selectHasEditorRole,
  selectSubscription,
  (hasEditorRole: boolean, subscription: Subscription | null) => hasEditorRole || subscription?.valid
);
export const selectPlan = createSelector(selectSubscription, (subscription: Subscription | null) => subscription && subscription.plan || null);
