<ng-container *ngIf="(config$ | async); else elseBlock">
  <header id="page-header" [loggedOut]="true"></header>

  <main id="page-main">
    <router-outlet></router-outlet>
  </main>

  <footer id="page-footer"></footer>
</ng-container>
<ng-template #elseBlock>
  <app-loader></app-loader>
</ng-template>
