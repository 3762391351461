import {createSelector} from '@ngrx/store';
import {CommonState} from "./reducer";
import {selectCommonState} from "../selectors";

export const selectIsPageNotFound = createSelector(
  selectCommonState,
  (state: CommonState) => state.isPageNotFound
);

export const selectIsPopupOpen = createSelector(
  selectCommonState,
  (state: CommonState) => state.isPopupOpen
);
