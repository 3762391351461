import {createReducer, on} from "@ngrx/store";
import {setCategories, setCourses} from "./actions";
import {Course, PostCategory} from "../../types";

export interface MealsState {
  categories: PostCategory[] | null;
  courses: Course[] | null;
}

export const initialState: MealsState = {
  categories: null,
  courses: null,
};

export const mealsReducer = createReducer(
  initialState,
  on(setCategories, (state, props: { categories: PostCategory[] }) => ({...state, categories: props.categories})),
  on(setCourses, (state, props:  { courses: Course[] }) => ({...state, courses: props.courses})),
);

