import { Injectable } from '@angular/core';
import {environment} from "../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {AuthService} from "./auth.service";

@Injectable({
  providedIn: 'root'
})
export class MediaFileService {

  private API_URL = environment.apiUrl;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
  ) {}

  protected encrypt(text: string, depth: number): string {
    if (depth <= 0 || !text) {
      return text;
    }

    const split = text.split('');
    const second = split.filter((item: string, index: number) => index % 2 === 1);
    const rest = split.filter((item: string, index: number) => index % 2 === 0);
    return this.encrypt(second.join('') + rest.join(''), depth - 1);
  }

  createChallenge(media_file_id: number, filename: string): Observable<{ challenge: string }> {
    return this.http.post<{ challenge: string }>(`${this.API_URL}/media-files/challenges`, {
      media_file_id,
      filename,
    });
  }

  getProtectedFileSource(media_file_id: number, filename: string, token: string): string {
    const [uuid, depth] = token.split(':');
    const encrypted = this.encrypt(uuid, Number(depth));
    return `${this.API_URL}/media-files/protected-files?filename=${filename}&media_file_id=${media_file_id}&file_token=${encrypted}:${depth}&token=${this.authService.getAccessToken()}`;
  }

  getFileSource(media_file_id: number, filename: string): string {
    return `${this.API_URL}/media-files/files?filename=${filename}&media_file_id=${media_file_id}&token=${this.authService.getAccessToken()}`;
  }
}
