import {Component, Input, OnInit} from '@angular/core';
import {selectContactEmail, selectContactInstagram, selectContactPhone, selectSiteName} from "../config/selectors";
import { Store } from '@ngrx/store';
import {AppState} from "../reducer";
import {environment} from "../../environments/environment";

@Component({
  selector: 'footer[id="page-footer"]',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  public siteName$ = this.store.select(selectSiteName);
  public contactEmail$ = this.store.select(selectContactEmail);
  public contactPhone$ = this.store.select(selectContactPhone);
  public contactInstagram$ = this.store.select(selectContactInstagram);
  public landingUrl = environment.landingUrl;

  year: number = new Date().getFullYear();

  constructor(private readonly store: Store<AppState>) { }

  ngOnInit(): void {
  }

}
