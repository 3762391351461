import {Component, HostBinding, Input, OnInit} from '@angular/core';
import {SafeHtml} from "@angular/platform-browser";
import {animate, state, style, transition, trigger} from "@angular/animations";

@Component({
  selector: 'app-accordion-item',
  templateUrl: './accordion-item.component.html',
  styleUrls: ['./accordion-item.component.scss'],
  animations: [
    trigger('slideUpDown', [
      state('1', style({ height: '*', paddingBottom: '*' })),
      state('0', style({ height: 0, paddingBottom: 0 })),
      transition('* => *', animate('400ms ease-in-out')),
    ])
  ]
})
export class AccordionItemComponent implements OnInit {

  @HostBinding('class.accordion-item') className: boolean = true;

  @Input() title: SafeHtml | null = null;
  @Input() contents: SafeHtml | null = null;

  public open: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  toggle() {
    this.open = !this.open;
  }
}
