import { Injectable } from '@angular/core';
import {PostCategory, Course, MealCourse} from "../types";
import {environment} from "../environments/environment";
import {Observable, forkJoin, map} from 'rxjs';
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class MealService {

  private API_URL = environment.apiUrl;

  constructor(private http: HttpClient) {}

  getCategoriesWithMeals(): Observable<PostCategory[]> {
    return this.http.get<PostCategory[]>(`${this.API_URL}/categories`, {
      params: {
        ['parent:parent_id__null']: 1,
        ['parent:slug']: 'diet',
        __with: 'posts',
        ['post:type']: 'meal',
      }
    });
  }

  getCourses(): Observable<Course[]> {
    return this.http.get<Course[]>(`${this.API_URL}/meals/courses`);
  }
}
