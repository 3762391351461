import {Component, Input, OnInit} from '@angular/core';
import {Course, Post} from "../../../types";

@Component({
  selector: 'app-diet-page-meal',
  templateUrl: './meal.component.html',
  styleUrls: ['./meal.component.scss']
})
export class MealComponent implements OnInit {

  @Input()
  public meal!: Post;

  constructor() { }

  ngOnInit(): void {
  }

  isComplex(course: Course): boolean {
    return !!course.description;
  }
}
