import { Injectable } from '@angular/core';
import {environment} from "../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Post, PostView} from "../types";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class PostService {

  private API_URL = environment.apiUrl;

  constructor(private http: HttpClient) {}

  markPostCompleted(post: Post): Observable<PostView> {
    return this.http.post<PostView>(`${this.API_URL}/posts/${post.id}/views`, { body: null });
  }

  getOne(id: number): Observable<Post> {
    return this.http.get<Post>(`${this.API_URL}/posts/${id}`, {
      params: {
        ['__with[]']: ['views', 'tags', 'media_file', 'category', 'category.parent']
      }
    });
  }
}
