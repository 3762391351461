import {Component, Inject} from '@angular/core';
import {selectIsPopupOpen} from "./common/selectors";
import {AppState} from "./reducer";
import {Store} from '@ngrx/store';
import {DOCUMENT} from "@angular/common";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  isPopupOpen$ = this.store.select(selectIsPopupOpen);

  constructor(
    private readonly store: Store<AppState>,
    @Inject(DOCUMENT) private document: Document,
  ) {
  }

  ngOnInit() {
    this.isPopupOpen$.subscribe(isPopupOpen => {
      this.document.body.classList[isPopupOpen ? 'add' : 'remove']('has-open-popup');
    })
  }
}
