import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import {AppState} from "../reducer";
import {selectSubscription} from "../user/selectors";
import {Subscription as ReactiveSubscription} from 'rxjs';
import {Subscription} from "../../types";

@Component({
  selector: 'app-payment-thanks',
  templateUrl: './payment-thanks.component.html',
  styleUrls: ['./payment-thanks.component.scss']
})
export class PaymentThanksComponent implements OnInit {
  private subscriptions: ReactiveSubscription[] = [];
  public subscription: Subscription | null = null;

  constructor(
    private readonly store: Store<AppState>,
  ) { }

  ngOnInit(): void {
    this.subscriptions.push(
      this.store.select(selectSubscription)
        .subscribe((subscription) => {
          this.subscription = subscription;
        })
    )
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
