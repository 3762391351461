import {createReducer, on} from "@ngrx/store";
import {closePopup, openPopup, resetPageNotFound, setPageNotFound} from "./actions";

export interface CommonState {
  isPageNotFound: boolean;
  isPopupOpen: boolean;
}

export const initialState: CommonState = {
  isPageNotFound: false,
  isPopupOpen: false,
};

export const commonReducer = createReducer(
  initialState,
  on(setPageNotFound, (state) => ({...state, isPageNotFound: true})),
  on(resetPageNotFound, (state) => ({...state, isPageNotFound: false})),
  on(openPopup, (state) => ({...state, isPopupOpen: true})),
  on(closePopup, (state) => ({...state, isPopupOpen: false})),
);

