import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import {AppState} from "../reducer";
import {selectEmail, selectFullName, selectFirstName, selectLastName, selectPhone} from "../user/selectors";
import {UserService} from "../user.service";
import {setUser} from "../user/actions";
import {tap} from 'rxjs/operators';
import {FormErrors, FormInputs, FormMessages, FormValues, User, UserUpdateRequest} from "../../types";
import {HttpErrorResponse} from "@angular/common/http";

@Component({
  selector: 'app-settings-page',
  templateUrl: './settings-page.component.html',
  styleUrls: ['./settings-page.component.scss']
})
export class SettingsPageComponent implements OnInit {

  userEmail$ = this.store.select(selectEmail);
  userPhone$ = this.store.select(selectPhone);
  userFirstName$ = this.store.select(selectFirstName);
  userLastName$ = this.store.select(selectLastName);
  userFullName$ = this.store.select(selectFullName);

  errors: FormErrors = {};
  errorMessages: FormMessages = {
    first_name: {
      'validation.required': 'Представьтесь, пожалуйста',
    },
    phone: {
      'validation.required': 'Пожалуйста, введите номер телефона',
      'validation.unique': 'Этот телефон уже зарегистрирован',
      'validation.regex': 'Пожалуйста, введите номер в международном формате',
    },
    password: {
      'validation.required': 'Пожалуйста, введите новый пароль',
      'validation.min.string': 'Пароль должен быть не менее 6 символов длиной',
    },
    previous_password: {
      'validation.required': 'Пожалуйста, введите текущий пароль',
      'validation.mismatch': 'Текущий пароль введён неверно',
    }
  };

  isEditingPhone: boolean = false;
  phoneInputs: FormInputs = [
    { name: 'phone', label: 'Телефон', type: 'phone' }
  ];
  phoneValues: FormValues = {};

  isEditingPassword: boolean = false;
  passwordInputs: FormInputs = [
    { name: 'previous_password', label: 'Текущий пароль', type: "password" },
    { name: 'password', label: 'Новый пароль', type: "password" },
  ];
  passwordValues: FormValues = {};

  isEditingName: boolean = false;
  nameInputs: FormInputs = [
    { name: 'first_name', label: 'Имя', maxLength: 255 },
    { name: 'last_name', label: 'Фамилия', maxLength: 255 },
  ];

  nameValues: FormValues = {};

  constructor(
    private readonly store: Store<AppState>,
    private readonly userService: UserService,
    ) { }

  ngOnInit(): void {
    this.userPhone$.subscribe((phone: string | null) => {
      this.phoneValues = { phone };
    });

    this.userFirstName$.subscribe((firstName: string | null) => {
      this.nameValues = {
        ...this.nameValues,
        first_name: firstName,
      };
    });

    this.userLastName$.subscribe((lastName: string | null) => {
      this.nameValues = {
        ...this.nameValues,
        last_name: lastName,
      };
    });
  }

  setErrors(errors: FormErrors) {
    this.errors = errors;
  }

  cancelEditingPhone() {
    this.isEditingPhone = false;
  }

  startEditingPhone() {
    this.isEditingPhone = true;
  }

  setPhone(formValues: FormValues): void {
    const phone = formValues['phone'];

    if (!phone) {
      return;
    }

    this.errors = {};
    this.userService.update({ phone })
      .pipe(
        tap(
          (user: User) => { this.store.dispatch(setUser(user)); this.isEditingPhone = false; },
          (response: HttpErrorResponse) => { this.setErrors(response.error) },
        )
      )
        .subscribe();
  }

  cancelEditingPassword() {
    this.isEditingPassword = false;
  }

  startEditingPassword() {
    this.isEditingPassword = true;
  }

  setPassword(formValues: FormValues): void {
    const password = formValues['password'];
    const previousPassword = formValues['previous_password'];

    if (!password || !previousPassword) {
      const errors: FormErrors = {};

      if (!password) {
        errors['password'] = ['validation.required'];
      }

      if (!previousPassword) {
        errors['previous_password'] = ['validation.required'];
      }

      this.setErrors(errors);

      return;
    }

    this.errors = {};
    this.userService.update({ password, previous_password: previousPassword })
      .pipe(
        tap(
          (user: User) => { this.store.dispatch(setUser(user)); this.isEditingPassword = false; },
          (response: HttpErrorResponse) => { this.setErrors(response.error) },
        )
      )
        .subscribe();
  }

  cancelEditingName() {
    this.isEditingName = false;
  }

  startEditingName() {
    this.isEditingName = true;
  }

  setName(formValues: FormValues): void {
    const firstName = formValues['first_name'];
    const lastName = formValues['last_name'];
    this.errors = {};

    if (!firstName) {
      this.setErrors({ first_name: ['validation.required']});
      return;
    }

    const updateData: UserUpdateRequest = { first_name: firstName };

    if (lastName !== null) {
      updateData.last_name = lastName;
    }

    this.userService.update(updateData)
      .pipe(
        tap(
          (user: User) => { this.store.dispatch(setUser(user)); this.isEditingName = false; },
          (response: HttpErrorResponse) => { this.setErrors(response.error) },
        )
      )
      .subscribe();
  }

  getErrors(): { [name: string]: string } {
    return Object.entries(this.errors).reduce<{ [name: string]: string }>((accum, [name, errors]) => {
      accum[name] = this.errorMessages[name][errors[0]];
      return accum;
    }, {})
  }

  canEdit(): boolean {
    return !this.isEditingPhone && !this.isEditingPassword && !this.isEditingName;
  }
}
