<section class="section">
  <div class="container">
    <header class="section-header"><h1>Настройки</h1></header>
    <main>
      <div class="table">
        <app-settings-page-row class="row"
                               label="Имя и фамилия"
                               [errors]="getErrors()"
                               [resultingValue]="userFullName$ | async"
                               (onStartEditing)="startEditingName()"
                               (onCancelEditing)="cancelEditingName()"
                               [canEdit]="canEdit()"
                               (onSave)="setName($event)"
                               [inputs]="nameInputs"
                               [values]="nameValues"
                               [editing]="isEditingName"></app-settings-page-row>

        <app-settings-page-row class="row"
                               label="Email"
                               [resultingValue]="userEmail$ | async"
                               [canEdit]="false"></app-settings-page-row>

        <app-settings-page-row class="row"
                               label="Телефон"
                               [errors]="getErrors()"
                               [resultingValue]="userPhone$ | async | formatPhoneNumber"
                               (onStartEditing)="startEditingPhone()"
                               (onCancelEditing)="cancelEditingPhone()"
                               [canEdit]="canEdit()"
                               (onSave)="setPhone($event)"
                               [inputs]="phoneInputs"
                               [values]="phoneValues"
                               [editing]="isEditingPhone"></app-settings-page-row>

        <app-settings-page-row class="row"
                               label="Пароль"
                               [errors]="getErrors()"
                               resultingValue="********"
                               (onStartEditing)="startEditingPassword()"
                               (onCancelEditing)="cancelEditingPassword()"
                               [canEdit]="canEdit()"
                               (onSave)="setPassword($event)"
                               [inputs]="passwordInputs"
                               [values]="passwordValues"
                               [editing]="isEditingPassword"></app-settings-page-row>
      </div>
    </main>
  </div>
</section>
