import {User} from "../../types";
import {createReducer, on} from "@ngrx/store";
import {setUser, setLoadingError} from "./actions";

export interface UserState {
    user: User | null;
    hasLoadingError: boolean;
}

export const initialState: UserState = {
  user: null,
  hasLoadingError: false,
};

export const userReducer = createReducer(
  initialState,
  on(setUser, ((state, user: User) => ({ ...state, user }))),
  on(setLoadingError, (state) => ({ ...state, hasLoadingError: true })),
);

