import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../environments/environment";
import {Config} from "../types";

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  private API_URL = environment.apiUrl;

  constructor(private http: HttpClient) {}

  get(): Observable<Config> {
    return this.http.get<Config>(`${this.API_URL}/config`);
  }
}
