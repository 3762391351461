import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../environments/environment";
import {Page} from "../types";
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PageService {

  private API_URL = environment.apiUrl;

  constructor(private http: HttpClient) {}

  getOneBySlug(slug: string): Observable<Page> {
    return this.http.get<Page>(`${this.API_URL}/page?slug=${slug}`);
  }
}
