import { Component, OnInit } from '@angular/core';
import {PostService} from "../post.service";
import {ActivatedRoute} from "@angular/router";
import {Post, PostView} from "../../types";
import {tap} from 'rxjs';

@Component({
  selector: 'app-training-page',
  templateUrl: './training-page.component.html',
  styleUrls: ['./training-page.component.scss']
})
export class TrainingPageComponent implements OnInit {

  training: Post | null = null;

  linkBack: any[] = [];

  constructor(
    private postService: PostService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.postService.getOne(this.activatedRoute.snapshot.params['id']).subscribe((training) => {
      this.training = training;
      this.linkBack = ['/trainings'];

      const category = training.category;
      const parent = category?.parent || null;

      if (parent?.parent_id !== null && parent?.slug) {
        this.linkBack.push(parent.slug);
      }

      if (category?.slug) {
        this.linkBack.push(category.slug);
      }
    });
  }

  markCompleted(): void {
    if (!this.training) {
      return;
    }

    this.postService.markPostCompleted(this.training)
      .pipe(
        tap((view: PostView) => {
          if (this.training) {
            this.training = {
              ...this.training,
              views: [...(this.training.views || []), view]
            };
          }
        })
      )
      .subscribe();
  }
}
