import {createSelector} from '@ngrx/store';
import {MealsState} from "./reducer";
import {selectMealsState} from "../selectors";
import {Course, MealCourse, PostCategory} from "../../types";

export const selectCategories = createSelector(
  selectMealsState,
  (state: MealsState) => state.categories,
);
export const selectCourses = createSelector(
  selectMealsState,
  (state: MealsState) => state.courses
);

export const selectCategoriesWithMealsAndCourses = createSelector(
  selectCategories,
  selectCourses,
  (categories: PostCategory[] | null, courses: Course[] | null) => {
    if (!categories) {
      return null;
    }

    if (!courses) {
      return categories;
    }

    const mealsCoursesByMealId = courses.reduce<{ [key: number]: MealCourse[] }>((accum, course) => {
      const { meals_courses: mealsCourses } = course;

      if (mealsCourses) {
        mealsCourses.forEach((mealCourse) => {
          const { meal_id: mealId } = mealCourse;
          if (!accum[mealId]) {
            accum[mealId] = [];
          }

          accum[mealId].push({
            ...mealCourse,
            course,
          });
        });
      }

      return accum;
    }, {});

    return categories.map(category => {
      return {
        ...category,
        posts: category.posts?.map(post => {
          return {
            ...post,
            meals_courses: (mealsCoursesByMealId[post.id] || []).slice()
              .sort((a, b) => a.order - b.order),
          }
        }),
      }
    })
  }
)
