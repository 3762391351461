import {Component, HostBinding, Input, OnInit, Output} from '@angular/core';
import {Post} from "../../types";
import {EventEmitter} from "@angular/core";

@Component({
  selector: 'app-training',
  templateUrl: './training.component.html',
  styleUrls: ['./training.component.scss']
})
export class TrainingComponent implements OnInit {

  @Input('training')
  set setTraining(training: Post) {
    this.training = training;
    this.views = training.views?.length || 0;
  }

  training!: Post;
  views: number = 0;

  @HostBinding('class.extended')
  @Input()
  showExtendedInfo: boolean = false;

  @Output()
  onComplete = new EventEmitter<void>();

  constructor() { }

  ngOnInit(): void {
  }


  markCompleted(): void {
    this.onComplete.emit();
  }
}
