import { Injectable } from '@angular/core';
import {environment} from "../environments/environment";
import {HttpClient} from "@angular/common/http";
import {User, UserUpdateRequest} from "../types";
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private API_URL = environment.apiUrl;

  constructor(private http: HttpClient) {}

  update(data: UserUpdateRequest): Observable<User> {
    return this.http.patch<User>(`${this.API_URL}/users/me`, data);
  }
}
