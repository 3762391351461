import { Injectable } from '@angular/core';
import {environment} from "../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {User, Plan, WayforpayOrderOptions} from "../types";

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  private ORDER_TYPE_WAYFORPAY = 'wayforpay';
  private API_URL = environment.apiUrl;

  constructor(private http: HttpClient) {}

  createWayforpayOrder(plan: Plan): Observable<WayforpayOrderOptions> {
    return this.http.post<WayforpayOrderOptions>(`${this.API_URL}/payments/orders`, {
      type: this.ORDER_TYPE_WAYFORPAY,
      plan_id: plan.id,
    });
  }


  createSubscription(user: User, orderReference: string): Observable<{ message: string }> {
    return this.http.post<{ message: string }>(`${this.API_URL}/payments/subscriptions`, {
      user_id: user.id,
      order_reference: orderReference,
    });
  }
}
