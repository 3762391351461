import {Injectable} from "@angular/core";
import { createEffect, Actions, ofType } from '@ngrx/effects';
import {USER_LOAD} from "./action-types";
import {AuthService} from "../auth.service";
import { of } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';
import {User} from "../../types";
import {setUser, setLoadingError} from "./actions";

@Injectable()
export class UserEffects {
  loadUser = createEffect(() =>
    this.action$.pipe(
      ofType(USER_LOAD),
      mergeMap(() => this.authService.get()
        .pipe(
          map((user: User) => (setUser(user)),
            catchError(() => of(setLoadingError())))
        )
      )
    )
  );

  constructor(
    private action$: Actions,
    private authService: AuthService,
  ) {
  }
}
