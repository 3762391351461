<app-loader *ngIf="!categories; else elseBlock"></app-loader>
<ng-template #elseBlock>
  <section class="section section-parents">
    <div class="container">
      <header class="section-header">
        <h1>Тренировки</h1>

        <nav class="tabs">
          <a class="tab"
             *ngFor="let category of categories"
             routerLinkActive="active"
             [routerLink]="[category.slug]"
             [innerHTML]="category.name | wrapWords | safeHtml">
          </a>
        </nav>
      </header>
    </div>
  </section>

  <router-outlet></router-outlet>
</ng-template>
