import {Component, OnInit, Output, EventEmitter, HostBinding, Input} from '@angular/core';
import { Store } from '@ngrx/store';
import {selectFirstName, selectInitials, selectHasAccessToContent} from "../user/selectors";
import {selectSiteName} from "../config/selectors";
import {AppState} from "../reducer";
import {Router} from "@angular/router";

@Component({
  selector: 'header[id="page-header"]',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public initials$ = this.store.select(selectInitials);
  public firstName$ = this.store.select(selectFirstName);
  public siteName$ = this.store.select(selectSiteName);
  public canViewInnerPages$ = this.store.select(selectHasAccessToContent);

  @Input()
  public loggedOut!: boolean;

  @Output() onLogout = new EventEmitter<void>();

  public isPopupVisible: boolean = false;

  @HostBinding('class.has-menu-open')
  public isMenuOpen = false;

  constructor(
    private readonly router: Router,
    private readonly store: Store<AppState>,
  ) { }

  ngOnInit(): void {
  }

  logout(): void {
    this.onLogout.emit();
  }

  togglePopup(): void {
    this.isPopupVisible = !this.isPopupVisible;
  }

  closePopup(): void {
    this.isPopupVisible = false;
  }

  toggleMenu(): void {
    this.isMenuOpen = !this.isMenuOpen;
  }

  closeMenu(): void {
    this.isMenuOpen = false;
  }

  isInTrainings(): boolean {
    return this.router.url.startsWith('/training');
  }
}
