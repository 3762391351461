import {Inject, Injectable} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, Subject, catchError, tap, of, switchMap, throwError } from 'rxjs';
import { DOCUMENT } from "@angular/common";
import {environment} from "../environments/environment";
import {AuthService} from "./auth.service";
import {Router} from "@angular/router";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly authService: AuthService,
    private readonly router: Router,
  ) {}

  refreshTokenInProgress = false;

  tokenRefreshedSource = new Subject<void>();
  tokenRefreshed$ = this.tokenRefreshedSource.asObservable();

  addAuthHeader(request: HttpRequest<unknown>) {
    const accessToken = this.authService.getAccessToken();

    if (accessToken) {
      return request.clone({
        setHeaders: { Authorization: `Bearer ${this.authService.getAccessToken()}` }
      });
    }

    return request;
  }

  refreshToken(): Observable<any> {
    if (this.refreshTokenInProgress) {
      return new Observable(observer => {
        this.tokenRefreshed$.subscribe(() => {
          observer.next();
          observer.complete();
        });
      });
    } else {
      this.refreshTokenInProgress = true;

      return this.authService.refresh().pipe(
        tap(() => {
          this.refreshTokenInProgress = false;
          this.tokenRefreshedSource.next();
        }),
        catchError((err) => {
          this.refreshTokenInProgress = false;
          this.logout();
          return of(err);
        }));
    }
  }

  logout(): void {
    this.document.location.href = environment.landingUrl;
  }

  handleResponseError(response: HttpErrorResponse, request: HttpRequest<unknown>, next: HttpHandler): Observable<any> {
    // Invalid token error
    if (response.status === 401) {
      if (response.url && /\/auth\/refresh/.test(response.url)) {
        this.logout();
      } else {
        return this.refreshToken().pipe(
          switchMap(() => {
            request = this.addAuthHeader(request);
            return next.handle(request);
          }),
          catchError(() => {
            return this.refreshToken().pipe(
              switchMap(() => {
                request = this.addAuthHeader(request);
                return next.handle(request);
              }),
              catchError(e => {
                if ([401, 403].includes(e.status)) {
                  this.logout();
                  return of(e);
                } else {
                  return throwError(e);
                }
              }),
            )
          }),
        )
      }
    } else if (response.status === 403) {
      this.logout();
      return of(response);
    }

    return throwError(response);
  }


  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    request = this.addAuthHeader(request);

    // Handle response
    return next.handle(request).pipe(
      catchError(error => {
        return this.handleResponseError(error, request, next);
      }),
    );
  }
}
