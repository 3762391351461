import { Component, OnInit } from '@angular/core';
import {AppState} from "../reducer";
import {setPageNotFound} from "../common/actions";
import {Store} from '@ngrx/store';

@Component({
  selector: 'app-not-found-page',
  templateUrl: './not-found-page.component.html',
  styleUrls: ['./not-found-page.component.scss']
})
export class NotFoundPageComponent implements OnInit {

  constructor(
    private readonly store: Store<AppState>,
  ) { }

  ngOnInit(): void {
    this.store.dispatch(setPageNotFound())
  }

}
