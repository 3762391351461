import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {PlyrComponent} from 'ngx-plyr';
import {MediaFile} from "../../../types";
import {Observable, forkJoin, mergeMap, from, map, of, catchError} from 'rxjs';
import {MediaFileService} from "../../media-file.service";
import {PlayerService} from "../../player.service";
import {Subscription as RxSubscription} from 'rxjs';
import {HlsjsPlyrDriver} from "../../hlsjs-plyr-driver";

@Component({
  selector: 'app-training-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VideoComponent implements OnInit, OnDestroy {

  private subscriptions: RxSubscription[] = [];

  @Input()
  startFullscreen: boolean = false;

  @Input('video')
  set setVideo(video: MediaFile) {
    this.video = video;

    this.setVideoProps().subscribe();
  };

  video: MediaFile | null = null;

  title: string = '';
  poster: string = '';
  isPlaying: boolean = false;
  playerElement: Element | null = null;

  hlsjsPlyrDriver = new HlsjsPlyrDriver(false);

  clickPlayHandler = (event: any) => {
    if (
      (event.target.dataset['plyr'] === 'play' && !event.target.classList.contains('plyr__control--pressed')) ||
      (
        event.target.classList.contains('plyr__poster') &&
        event.target.parentElement?.parentElement?.classList.contains('plyr--paused')
      )
    ) {
      this.onClickPlay();
    }
  }

  videoSources: Plyr.Source[] = [];

  @ViewChild(PlyrComponent)
  plyr: PlyrComponent | undefined;

  constructor(
    private mediaFileService: MediaFileService,
    private changeDetectorRef: ChangeDetectorRef,
    private readonly playerService: PlayerService,
  ) { }

  setVideoProps(): Observable<void> {
    const video = this.video;

    if (!video) {
      return of();
    }

    const sourceRequests = video.links.reduce<{ [source: string]: Observable<{ challenge: string } | null>}>((accum, item) => {
      accum[item.filename] = this.mediaFileService.createChallenge(video.id, item.filename)
        .pipe(
          catchError(() => of(null))
        );
      return accum;
    }, {});

    return forkJoin(sourceRequests)
      .pipe(
        map(challenges => {
          this.videoSources = video.links.map(link => {
            const challenge = challenges[link.filename]?.challenge;
            if (!challenge) {
              return null;
            }

            const source: Plyr.Source = {
              size: link.size,
              src: this.mediaFileService.getProtectedFileSource(video.id, link.filename, challenge),
            };

            if (link.type) {
              source.type = link.type;
            }

            return source;
          })
            .filter((source): source is Plyr.Source => source !== null);

          this.title = video.name;

          if (video.preview_link) {
            this.poster = this.mediaFileService.getFileSource(video.id, video.preview_link.filename);
          } else {
            this.poster = '';
          }

          this.changeDetectorRef.detectChanges();
        })
      );
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.playerService.currentlyPlaying.subscribe((id: number | null) => {
        if (this.video && this.video.id !== id && this.plyr && this.plyr.player.playing) {
          this.plyr.player.pause();
        }
      })
    )
  }

  ngOnDestroy() {
    if (this.playerElement) {
      this.playerElement.removeEventListener('click', this.clickPlayHandler);
    }

    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  ready($event: any) {
    this.playerElement = $event.target;

    if (this.playerElement) {
      this.playerElement.addEventListener('click', this.clickPlayHandler);
    }
  }

  played(event: any) {
    if (this.video?.id) {
      this.playerService.startPlaying(this.video.id);
    }
  }

  paused(event: any) {
    if (this.video?.id) {
      this.playerService.stopPlaying(this.video.id);
    }
  }

  onError(event: any) {
    if (this.plyr) {
      const player = this.plyr.player;

      // If the media error is "Unsupported source type"
      // @ts-ignore
      if (player.media.error?.code === 4) {
        const currentTime = player.currentTime || 0;
        const speed = player.speed || 1;

        this.setVideoProps().pipe(
          mergeMap(() => {
            if (this.isPlaying) {

              const promise = player.play();
              if (!promise) {
                return of();
              }

              return from(promise);
            }

            return of();
          })
        ).subscribe(() => {
          if (this.isPlaying) {
            player.speed = speed;
            player.currentTime = currentTime;
          }
        });
      }
    }
  }

  onPlaying(): void {
    this.isPlaying = true;
  }

  onClickPlay(): void {
    if (!this.plyr || !this.video) {
      return;
    }

    const player = this.plyr.player;

    if (this.startFullscreen && !player.fullscreen.active) {
      player.fullscreen.enter();
    }

    if (this.videoSources.length) {
      this.hlsjsPlyrDriver.load(this.videoSources[0].src);
    }
  }
}
