import { Injectable } from '@angular/core';
import {environment} from "../environments/environment";
import {HttpClient} from "@angular/common/http";
import {PostCategory} from "../types";
import {Observable, map} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  private API_URL = environment.apiUrl;

  constructor(private http: HttpClient) {}

  getRecommendationsWithPosts(): Observable<PostCategory[]> {
    return this.http.get<PostCategory[]>(`${this.API_URL}/categories`, {
      params: {
        ['parent:parent_id__null']: 1,
        ['parent:slug']: 'recommendations',
        __with: 'posts',
        slug__ne: 'landing',
        ['post:type']: 'faq',
      }
    });
  }

  private _getCategoriesWithViewed(categories: PostCategory[]): PostCategory[] {
    return categories.map(category => {
      return {
        ...category,
        viewed: category.posts?.length ? category.posts.every(post => post.views?.length) : false,
        children: category.children ? this._getCategoriesWithViewed(category.children) : undefined,
      }
    })
  }

  getTrainingTree(): Observable<PostCategory[]> {
    return this.http.get<PostCategory[]>(`${this.API_URL}/categories`, {
      params: {
        ['__with[]']: ['posts', 'posts.views', 'posts.tags', 'posts.media_file', 'children'],
        ['parent:slug']: 'training',
        ['parent:parent_id__null']: 1,
        ['post:type']: 'video',
      }
    })
      .pipe(
        map(categories => this._getCategoriesWithViewed(categories))
      );
  }
}
