import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {AuthService} from "../auth.service";
import {environment} from "../../environments/environment";
import {DOCUMENT} from "@angular/common";
import {Store} from '@ngrx/store';
import {filter} from 'rxjs';
import {selectConfig} from "../config/selectors";
import {selectUser} from "../user/selectors";
import {loadConfig} from "../config/actions";
import {AppState} from "../reducer";
import {NavigationEnd, Router} from "@angular/router";
import {selectIsPageNotFound} from "../common/selectors";
import {resetPageNotFound} from "../common/actions";
import {Subscription as ReactiveSubscription, tap, switchMap} from 'rxjs';
import {setUser} from "../user/actions";
import {User} from "../../types";

@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss']
})
export class PageComponent implements OnInit, OnDestroy {
  private subscriptions: ReactiveSubscription[] = [];
  config$ = this.store.select(selectConfig);
  user$ = this.store.select(selectUser);
  isPageNotFound$ = this.store.select(selectIsPageNotFound);

  constructor(
    private authService: AuthService,
    @Inject(DOCUMENT) private document: Document,
    private readonly store: Store<AppState>,
    private readonly router: Router,
  ) {
  }

  ngOnInit() {
    this.store.dispatch(loadConfig());

    const validateUser = (user: User) => {
      if (![AuthService.ROLE_EDITOR, AuthService.ROLE_ADMIN].includes(user.role) && !user.subscription?.valid) {
        this.router.navigate(['/payment']);
      }
    };

    this.subscriptions.push(
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd),
        tap(() => { this.store.dispatch(resetPageNotFound()) }),
        switchMap(() => this.authService.get()),
        tap(user => { this.store.dispatch(setUser(user)); }),
      ).subscribe(validateUser),

      this.authService.get().pipe(
        tap(user => { this.store.dispatch(setUser(user)); }),
      )
        .subscribe(validateUser)
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  logout(): void {
    this.authService.logout().subscribe(() => {
      this.document.location.href = environment.landingUrl;
    });
  }

  hasParentRouteSegment(segment: string) {
    return this.router.url === `/${segment}` || this.router.url.startsWith(`/${segment}/`);
  }
}
