<section class="section section-inner-page section-center">
  <div class="container">
    <header class="section-header">
      <h2 class="text-logo"><span class="text-logo-subtitle">Онлайн-курс</span> Тянусь к&nbsp;совершенству</h2>
    </header>
    <main>
      <article class="paper paper-padded stack">
        <header class="stack stack-xs">
          <h2 class="header-accent">Упс!</h2>
          <h1>Возникла ошибка</h1>
        </header>
        <main class="stack stack-xs text-important">
          <p>К&nbsp;сожалению, у&nbsp;нас тут что-то пошло не&nbsp;так. Попробуйте перезагрузить страницу через несколько минут или проверить интернет-соединение.</p>
          <p *ngIf="message">Что-то на программистском: <span class="error">{{message}}</span></p>
        </main>
        <footer class="actions">
          <a (click)="reload()" class="button button-primary button-large">Перезагрузить</a>
        </footer>
      </article>
    </main>
  </div>
</section>
