import { Injectable } from '@angular/core';
import {environment} from "../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from 'rxjs';
import {Plan} from "../types";

@Injectable({
  providedIn: 'root'
})
export class PlanService {


  private API_URL = environment.apiUrl;

  constructor(private http: HttpClient) {}

  getAll(): Observable<Plan[]> {
    return this.http.get<Plan[]>(`${this.API_URL}/plans`);
  }
}
