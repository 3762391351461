import {Component, Input, OnInit, Output, EventEmitter, HostBinding} from '@angular/core';
import {FormInputs, FormValues} from "../../../types";

@Component({
  selector: 'app-settings-page-row',
  templateUrl: './row.component.html',
  styleUrls: ['./row.component.scss']
})
export class RowComponent implements OnInit {
  @Input() label!: string;
  @Input() resultingValue: string | null = null;

  @Input('values') set setValue(values: FormValues) {
    this.originalValues = values;

    if (!this.editing) {
      this.values = { ...values };
    }
  };

  @HostBinding('class.editing')
  @Input() editing: boolean = false;
  @Input() canEdit: boolean = false;

  @Input() errors: { [name: string]: string } = {};

  @Input() inputs: FormInputs = [];

  @Output() onStartEditing = new EventEmitter<void>();
  @Output() onCancelEditing = new EventEmitter<void>();
  @Output() onSave = new EventEmitter<FormValues>();

  private originalValues: FormValues = {};
  public values: FormValues = {};
  public submitted: { [name: string]: boolean } = {};

  constructor() { }

  ngOnInit(): void {
    this.resetSubmitted();
  }

  private resetSubmitted() {
    this.submitted = this.inputs.reduce<{ [name: string]: boolean }>((accum, item) => {
      accum[item.name] = true;
      return accum;
    }, {});
  }

  startEditing(): void {
    this.values = { ...this.originalValues };
    this.onStartEditing.emit();
  }

  cancelEditing(): void {
    this.onCancelEditing.emit();
    this.resetSubmitted();
  }

  updateValue(name: string, value: string) {
    this.values[name] = value;
    this.submitted[name] = false;
  }

  saveEditing(): void {
    this.resetSubmitted();
    this.onSave.emit(this.values);
  }
}
