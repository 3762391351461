<ul *ngIf="!showExtendedInfo && tags['zone'].length" class="tags-short tags-zone">
  <li *ngFor="let tag of tags['zone']">{{tag}}</li>
</ul>

<section *ngIf="showExtendedInfo" class="tags-extended">
  <ng-container *ngIf="tags['zone'].length">
    <h4 class="secondary">Направление</h4>

    <ul class="tags-zone">
      <li *ngFor="let tag of tags['zone']">{{tag}}</li>
    </ul>
  </ng-container>

  <ng-container *ngIf="tags['equipment']?.length">
    <h4 class="secondary">Подготовь</h4>

    <ul class="tags-equipment">
      <li *ngFor="let tag of tags['equipment']"><span class="material-icons">check</span>{{tag}}</li>
    </ul>
  </ng-container>
</section>
