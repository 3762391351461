import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {TrainingsPageComponent} from "./trainings-page/trainings-page.component";
import {PageComponent} from "./page/page.component";
import {NotFoundPageComponent} from "./not-found-page/not-found-page.component";
import {TrainingPageComponent} from "./training-page/training-page.component";
import {DietPageComponent} from "./diet-page/diet-page.component";
import {RecommendationsPageComponent} from "./recommendations-page/recommendations-page.component";
import {SettingsPageComponent} from "./settings-page/settings-page.component";
import {PaymentPageComponent} from "./payment-page/payment-page.component";
import {PaymentFormComponent} from "./payment-form/payment-form.component";
import {ActiveUserGuard} from "./active-user.guard";
import {UserResolver} from "./user.resolver";
import {PaymentThanksComponent} from "./payment-thanks/payment-thanks.component";
import {RecoverPasswordPageComponent} from "./recover-password-page/recover-password-page.component";
import {NewPasswordPageComponent} from "./new-password-page/new-password-page.component";
import {LoginPageComponent} from "./login-page/login-page.component";
import {LoggedOutPageComponent} from "./logged-out-page/logged-out-page.component";
import {RecipePopupComponent} from "./recipe-popup/recipe-popup.component";
import {ErrorPageComponent} from "./error-page/error-page.component";
import {PaymentPendingComponent} from "./payment-pending/payment-pending.component";
import {TrainingsChildCategoryComponent} from "./trainings-child-category/trainings-child-category.component";

const routes: Routes = [
  {
    path: 'error',
    component: ErrorPageComponent,
  },
  {
    path: 'forgot-password',
    component: LoggedOutPageComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: RecoverPasswordPageComponent,
      },
      {
        path: 'token/:token',
        component: NewPasswordPageComponent,
      },
    ]
  },
  {
    path: 'login',
    component: LoggedOutPageComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: LoginPageComponent,
      },
    ]
  },
  {
    path: '',
    component: PageComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'trainings',
      },
      {
        path: 'trainings',
        component: TrainingsPageComponent,
        children: [
          {
            path: '',
            pathMatch: 'full',
            component: TrainingsChildCategoryComponent,
          },
          {
            path: ':parentCategory',
            pathMatch: 'full',
            component: TrainingsChildCategoryComponent,
          },
          {
            path: ':parentCategory/:childCategory',
            component: TrainingsChildCategoryComponent,
          },
        ]
      },
      {
        path: 'training/:id',
        component: TrainingPageComponent,
      },
      {
        path: 'diet',
        component: DietPageComponent,
        children: [
          {
            path: 'recipe/:id',
            component: RecipePopupComponent,
            outlet: 'popup',
          },
        ],
      },
      {
        path: 'recommendations',
        component: RecommendationsPageComponent,
      },
    ],
  },
  {
    path: '',
    component: PageComponent,
    resolve: { user: UserResolver },
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'payment',
      },
      {
        path: 'payment',
        component: PaymentPageComponent,
        children: [
          {
            path: '',
            pathMatch: 'full',
            component: PaymentFormComponent,
          },
          {
            path: 'thanks',
            component: PaymentThanksComponent,
          },
          {
            path: 'pending',
            component: PaymentPendingComponent,
          }
        ]
      },
      {
        path: 'settings',
        component: SettingsPageComponent,
      },
    ]
  },
  {
    path: '**',
    component: NotFoundPageComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
