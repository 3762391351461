<div class="section section-children" *ngIf="parentCategory && parentCategory.children && parentCategory.children.length">
  <div class="container">
    <div class="tabs-scroll-container">
      <nav class="tabs tabs-buttons">
        <a class="tab"
           #childCategory
           *ngFor="let category of parentCategory.children"
           routerLinkActive="active"
           [class.viewed]="category.viewed"
           [routerLink]="['..', category.slug]">
          {{category.name}}
        </a>
      </nav>
    </div>
  </div>
</div>

<app-trainings-list [category]="selectedCategory" *ngIf="selectedCategory"></app-trainings-list>
