import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'wrapWords'
})
export class WrapWordsPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): string {
    return value.split(' ').map(piece => `<span>${piece}</span>`).join(' ');
  }

}
