import {Injectable} from "@angular/core";
import { createEffect, Actions, ofType } from '@ngrx/effects';
import {CONFIG_LOAD} from "./action-types";
import {ConfigService} from "../config.service";
import { of } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';
import {Config} from "../../types";
import {setConfig, setLoadingError} from "./actions";

@Injectable()
export class ConfigEffects {
  loadConfig = createEffect(() =>
    this.action$.pipe(
      ofType(CONFIG_LOAD),
      mergeMap(() => this.configService.get()
        .pipe(
          map((config: Config) => (setConfig({ config })),
            catchError(() => of(setLoadingError())))
        )
      )
    )
  );

  constructor(
    private action$: Actions,
    private configService: ConfigService,
  ) {
  }
}
