<article class="paper paper-padded stack">
  <header>
    <h1>Спасибо за покупку!</h1>
  </header>

  <main class="stack stack-xs">
    <p>Платёж ещё в процессе, но как только деньги поступят, ваш доступ в кабинет будет активирован. При этом вам на почту поступит уведомление об успешном платеже от платёжной системы.</p>
    <p>Приятных тренировок!</p>
  </main>
</article>
