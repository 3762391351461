<ng-container>
  <div
    plyr
    plyrTitle="{{title}}"
    plyrType="video"
    [plyrOptions]="{ ratio: '16:9' }"
    [plyrPlaysInline]="true"
    [plyrSources]="videoSources"
    [plyrPoster]="poster"
    [plyrDriver]="hlsjsPlyrDriver"
    (plyrPlaying)="onPlaying()"
    (plyrError)="onError($event)"
    (plyrReady)="ready($event)"
    (plyrPlay)="played($event)"
    (plyrPause)="paused($event)"
  >
  </div>

</ng-container>
