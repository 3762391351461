import {ErrorHandler, Injectable, LOCALE_ID, NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './footer/footer.component';
import { HttpClientModule } from "@angular/common/http";
import { HeaderComponent } from './header/header.component';
import { FormatPhoneNumberPipe } from './format-phone-number.pipe';
import { TrainingsPageComponent } from './trainings-page/trainings-page.component';
import {httpInterceptorProviders} from "./http-interceptors";
import { PageComponent } from './page/page.component';
import { LoaderComponent } from './loader/loader.component';
import { ProfilePopupComponent } from './profile-popup/profile-popup.component';
import { NotFoundPageComponent } from './not-found-page/not-found-page.component';
import { SettingsPageComponent } from './settings-page/settings-page.component';
import { RecommendationsPageComponent } from './recommendations-page/recommendations-page.component';
import { TrainingPageComponent } from './training-page/training-page.component';
import { DietPageComponent } from './diet-page/diet-page.component';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import {configReducer} from "./config/reducer";
import {userReducer} from "./user/reducer";
import {ConfigEffects} from "./config/effects";
import {UserEffects} from "./user/effects";
import { RowComponent } from './settings-page/row/row.component';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import {environment} from "../environments/environment";
import { EditorComponent } from './settings-page/editor/editor.component';
import { IMaskModule } from 'angular-imask';
import { AccordionItemComponent } from './accordion-item/accordion-item.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { MealComponent } from './diet-page/meal/meal.component';
import { MomentModule } from 'ngx-moment';
import { WrapWordsPipe } from './wrap-words.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';
import { TrainingComponent } from './training/training.component';
import { PlyrModule } from 'ngx-plyr';
import { TagsComponent } from './training/tags/tags.component';
import { VideoComponent } from './training/video/video.component';
import { registerLocaleData } from "@angular/common";
import localeRu from '@angular/common/locales/ru';
import { WayforpayButtonComponent } from './wayforpay-button/wayforpay-button.component';
import { PaymentPageComponent } from './payment-page/payment-page.component';
import { PaymentStripeComponent } from './payment-stripe/payment-stripe.component';
import { PaymentFormComponent } from './payment-form/payment-form.component';
import {FormsModule} from "@angular/forms";
import {commonReducer} from './common/reducer';
import { PaymentThanksComponent } from './payment-thanks/payment-thanks.component';
import { RecoverPasswordPageComponent } from './recover-password-page/recover-password-page.component';
import { NewPasswordPageComponent } from './new-password-page/new-password-page.component';
import { LoginPageComponent } from './login-page/login-page.component';
import { LoggedOutPageComponent } from './logged-out-page/logged-out-page.component';
import { RecaptchaModule, RECAPTCHA_SETTINGS, RecaptchaSettings } from "ng-recaptcha";
import { RecipePopupComponent } from './recipe-popup/recipe-popup.component';
import {mealsReducer} from "./meals/reducer";
import { RecipeComponent } from './recipe/recipe.component';
import { ErrorPageComponent } from './error-page/error-page.component';
import {Router} from "@angular/router";
import { PaymentPendingComponent } from './payment-pending/payment-pending.component';
import { TrainingsListComponent } from './trainings-list/trainings-list.component';
import { TrainingsChildCategoryComponent } from './trainings-child-category/trainings-child-category.component';
import {trainingsReducer} from "./trainings/reducer";

registerLocaleData(localeRu);

@Injectable()
class MyErrorHandler implements ErrorHandler {
  constructor(
    private readonly router: Router,
  ) {
  }

  handleError(error: any) {
    this.router.navigate(['/error'] , { queryParams: { message: error.message }, skipLocationChange: true });

    // Rethrow for console errors
    throw error;
  }
}

const store = {
  configState: configReducer,
  userState: userReducer,
  commonState: commonReducer,
  mealsState: mealsReducer,
  trainingsState: trainingsReducer,
};

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    HeaderComponent,
    FormatPhoneNumberPipe,
    TrainingsPageComponent,
    PageComponent,
    LoaderComponent,
    ProfilePopupComponent,
    NotFoundPageComponent,
    SettingsPageComponent,
    RecommendationsPageComponent,
    TrainingPageComponent,
    DietPageComponent,
    RowComponent,
    EditorComponent,
    AccordionItemComponent,
    MealComponent,
    WrapWordsPipe,
    SafeHtmlPipe,
    TrainingComponent,
    TagsComponent,
    VideoComponent,
    WayforpayButtonComponent,
    PaymentPageComponent,
    PaymentStripeComponent,
    PaymentFormComponent,
    PaymentThanksComponent,
    RecoverPasswordPageComponent,
    NewPasswordPageComponent,
    LoginPageComponent,
    LoggedOutPageComponent,
    RecipePopupComponent,
    RecipeComponent,
    ErrorPageComponent,
    PaymentPendingComponent,
    TrainingsListComponent,
    TrainingsChildCategoryComponent,
  ],
  imports: [
    IMaskModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    StoreModule.forRoot(store, {}),
    EffectsModule.forRoot([ConfigEffects, UserEffects]),

    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
      autoPause: true, // Pauses recording actions and state changes when the extension window is not open
    }),

    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),

    CarouselModule,
    MomentModule,
    PlyrModule,
    FormsModule,
    RecaptchaModule,
  ],
  providers: [
    httpInterceptorProviders,
    { provide: LOCALE_ID, useValue: 'ru' },
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: { siteKey: environment.recaptchaSiteKey } as RecaptchaSettings,
    },
    {provide: ErrorHandler, useClass: MyErrorHandler},
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
